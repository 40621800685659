import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { stringify } from 'qs';
import { map, Observable } from 'rxjs';
import StrapiReturnDataUtils from '../../helpers/strapi-return-data.helper';
import { ClientMapper } from '../../mappers';
import {
  Client,
  ClientLight,
  StrapiPageResult,
  StrapiQueryParams,
} from '../../models';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  private readonly URL = 'api/clients';

  private queryParamsLight: StrapiQueryParams = {
    fields: ['name'],
  };

  constructor(private readonly http: HttpClient) {}

  getAllLight(): Observable<ClientLight[]> {
    const paramsSt = stringify(this.queryParamsLight);

    return this.http
      .get<StrapiPageResult<Client>>(`${this.URL}?${paramsSt}`)
      .pipe(
        map(
          (clients) =>
            StrapiReturnDataUtils.parseArray(clients) as ClientLight[],
        ),
      );
  }

  getClientsOptions() {
    return this.getAllLight().pipe(
      map((clients) => ClientMapper.clientsLightToOptions(clients)),
    );
  }
}
