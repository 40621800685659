import { Cabinet, CabinetLight, SelectOption } from '../models';

export class CabinetMapper {
  static cabinetsLightToOptions(cabinets: CabinetLight[]): SelectOption[] {
    return cabinets.map((cabinet) => {
      return this.cabinetLightToOption(cabinet);
    });
  }

  static cabinetLightToOption(cabinet: CabinetLight): SelectOption {
    return {
      value: cabinet.id,
      label: cabinet.reference,
    };
  }

  static cabinetsToCabinetsLight(cabinets: Cabinet[]): CabinetLight[] {
    return cabinets.map((cabinet) => {
      return this.cabinetToCabinetLight(cabinet);
    });
  }

  static cabinetToCabinetLight(cabinet: Cabinet): CabinetLight {
    const { id, reference } = cabinet;
    return {
      id,
      reference,
    };
  }
}
