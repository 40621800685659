import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imgFolder',
  standalone: true
})
export class ImgFolderPipe implements PipeTransform {

  transform(imageName: string, folder: string): unknown {
    return `images/${folder}/${imageName}`;
  }

}
