import { Battery, BatteryLight, BatteryMinimal, SelectOption } from '../models';

export class BatteryMapper {
  static batteryDtoToBatteryMinimal(batteryDto: Battery): BatteryMinimal {
    return {
      id: batteryDto?.id,
      partNumber: batteryDto?.partNumber,
    };
  }

  static batteriesLightDtoToSelectOptions(
    batteriesLight: BatteryLight[],
  ): SelectOption[] {
    return batteriesLight.map((batteryLight) => {
      return this.batteryLightDtoToSelectOption(batteryLight);
    });
  }

  static batteryLightDtoToSelectOption(
    batteryLight: BatteryLight,
  ): SelectOption {
    return {
      value: batteryLight.id,
      label: batteryLight.partNumber,
    };
  }
}
