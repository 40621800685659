import { NzSegmentedOptions } from 'ng-zorro-antd/segmented';
import { NzSegmentedOption } from 'ng-zorro-antd/segmented/types';
import { ProjectStatus, ProjectStatusHelper } from '../enums';
import { SelectOption } from '../models';

export class ProjectStatusMapper {
  static projectStatusesToOptions(
    projectStatuses: ProjectStatus[],
  ): SelectOption[] {
    return projectStatuses.map((projectStatus) => {
      return this.projectStatusToOption(projectStatus);
    });
  }

  static projectStatusToOption(projectStatus: ProjectStatus): SelectOption {
    return {
      value: projectStatus,
      label: ProjectStatusHelper.enumToLabelKey(projectStatus),
    };
  }

  static projectStatusToSegmentedOptions(
    projectStatuses: ProjectStatus[],
  ): NzSegmentedOptions {
    // Same format
    let segmentedOptions = ProjectStatusMapper.projectStatusesToOptions(
      projectStatuses,
    ) as NzSegmentedOptions;

    segmentedOptions = segmentedOptions.map((item) => {
      return { ...(item as NzSegmentedOption), disabled: false };
    });
    return segmentedOptions;
  }
}
