import { Orientation, OrientationHelper } from '../enums';
import { SelectOption } from '../models';

export class OrientationMapper {
  static orientationsToOptions(orientations: Orientation[]): SelectOption[] {
    return orientations.map((orientation) => {
      return this.orientationToOption(orientation);
    });
  }

  static orientationToOption(orientation: Orientation): SelectOption {
    return {
      value: orientation,
      label: OrientationHelper.enumToLabelKey(orientation),
    };
  }
}
