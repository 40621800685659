import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { fromFetch } from 'rxjs/internal/observable/dom/fetch';
import { StrapiFile } from '../../models/strapi/strapi-file.model';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private readonly httpClient: HttpClient) {}

  upload(formData: FormData): Observable<StrapiFile[]> {
    return this.httpClient.post<StrapiFile[]>('api/upload', formData);
  }

  download(file: StrapiFile) {
    return fromFetch(file.url).pipe(
      switchMap((it) => (!it.ok ? throwError(() => Error('error')) : of(it))),
      switchMap((it) => from(it.blob())),
      tap((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = file.name;
        link.click();
        URL.revokeObjectURL(link.href);
      }),
    );
  }
}
