import {
  Installer,
  InstallerLight,
  InstallerMinimal,
  SelectOption,
} from '../models';

export class InstallerMapper {
  static installersLightToOptions(
    installers: InstallerLight[],
  ): SelectOption[] {
    return installers.map((installer) => {
      return this.installerLightToOption(installer);
    });
  }

  static installerLightToOption(installer: InstallerLight): SelectOption {
    return {
      value: installer.id,
      label: this.formatInstallerLabel(installer),
    };
  }

  static formatInstallerLabel(
    installer: InstallerLight | Installer | InstallerMinimal,
  ) {
    return installer.society ?? `${installer.firstName} ${installer.lastName}`;
  }
}
