import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, map, Observable } from 'rxjs';

import { User, UserLogged } from '../../models';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly userKey: string = 'user';
  private currentUser$ = new BehaviorSubject<User | null>(null);
  private currentUserToken$ = new BehaviorSubject<string | null>(null);

  constructor(
    private readonly http: HttpClient,
    private readonly router: Router
  ) {
    const userString = sessionStorage.getItem(this.userKey);

    if (userString !== null) {
      this.currentUser$.next(JSON.parse(userString).user);
      this.currentUserToken$.next(JSON.parse(userString).jwt);
    } else {
      this.currentUser$.next(null);
      this.currentUserToken$.next(null);
    }
  }

  /**
   * Login to Power Suite back
   * @param user
   */
  public login(user: User): Observable<User> {
    return this.http
      .post<UserLogged>(`api/auth/local`, {
        identifier: user.email,
        password: user.password
      })
      .pipe(
        map((userLogged: UserLogged) => {
          sessionStorage.setItem(this.userKey, JSON.stringify(userLogged));

          this.currentUser$.next(userLogged.user);
          this.currentUserToken$.next(userLogged.jwt);
          return userLogged.user;
        }));
  }

  /**
   * Logout from app
   * The user stored is removed
   */
  public logout(): void {
    sessionStorage.clear();
    this.currentUser$.next(null);
    this.currentUserToken$.next(null);

    this.router.navigate(['login']);
  };

  public getAccessToken() {
    return this.currentToken;
  }

  /**
   * Get current user
   */
  public get currentUser() {
    return this.currentUser$.value;
  }

  /**
   * Get current token stocked in local storage
   * @private
   */
  private get currentToken() {
    return this.currentUserToken$.value;
  }
}
