import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { subMonths } from 'date-fns';
import { stringify } from 'qs';
import { map, Observable, of } from 'rxjs';
import { ProjectStatus, ProjectStatusFilter } from '../../enums';
import StrapiReturnDataUtils from '../../helpers/strapi-return-data.helper';
import {
  PageRequest,
  Project,
  ProjectCreate,
  StrapiPageResult,
  StrapiQueryParams,
} from '../../models';

@Injectable({ providedIn: 'root' })
export class ProjectService {
  private readonly URL = 'api/projects';
  private queryParams: StrapiQueryParams = {
    populate: {
      planFile: true,
      client: {
        fields: ['name'],
        populate: {
          logo: {
            fields: ['formats', 'url', 'mime'],
          },
        },
      },
      installer: {
        fields: ['firstName', 'lastName', 'society'],
      },
      battery: {
        fields: ['partNumber'],
        populate: {
          connector: true,
        },
      },
      cabinets: {
        fields: ['reference'],
      },
      rack: {
        fields: ['reference'],
      },
      deliverySlipFiles: true,
      invoiceFiles: true,
      paletteNumberFiles: true,
      commandFiles: true,
    },
  };

  constructor(private readonly http: HttpClient) {}

  getAllProjects(
    pageRequest: PageRequest<Project>,
    projectStatusFilter: ProjectStatusFilter,
  ) {
    // Page request
    this.queryParams.pagination = {
      pageSize: pageRequest.size,
      page: pageRequest.page,
    };

    // Sort
    if (pageRequest.sort) {
      this.queryParams.sort = [
        `${pageRequest.sort?.property.toString()}:${pageRequest.sort?.order}`,
      ];
    }

    // Project status filter
    this.generateProjectStatusFilterQueryParams(projectStatusFilter);

    const paramsSt = stringify(this.queryParams);
    return this.http.get<StrapiPageResult<Project>>(`${this.URL}?${paramsSt}`);
  }

  private generateProjectStatusFilterQueryParams(
    projectStatusFilter: ProjectStatusFilter,
  ) {
    switch (projectStatusFilter) {
      case ProjectStatusFilter.ALL_PROJECTS:
        delete this.queryParams.filters;
        break;
      case ProjectStatusFilter.TO_DRAW:
        this.queryParams.filters = {
          projectStatus: {
            $in: [ProjectStatus.ORDER_ENTRY, ProjectStatus.PLAN_DONE],
          },
        };
        break;
      case ProjectStatusFilter.URGENT_DELIVERIES:
        this.populateUrgentDeliveriesFilter(this.queryParams);
        break;
    }
  }

  /**
   * Urgent deliveries : deliveryDate - now < 1 month and projectStatus !== TO_INSTALL, BILLABLE, BILLED
   * @param params
   * @private
   */
  private populateUrgentDeliveriesFilter(params: StrapiQueryParams) {
    const oneMonthAgo = subMonths(new Date(), 1);
    params.filters = {
      $and: [
        {
          deliveryDate: {
            $gte: oneMonthAgo,
          },
        },
        {
          projectStatus: {
            $notIn: [
              ProjectStatus.TO_BE_INSTALLED,
              ProjectStatus.TO_BE_INVOICED,
              ProjectStatus.INVOICED,
            ],
          },
        },
      ],
    };
  }

  getProject(projectId: string): Observable<Project> {
    const paramsSt = stringify(this.queryParams);
    return this.http
      .get<Project>(`${this.URL}/${projectId}?${paramsSt}`)
      .pipe(
        map((projectStrapi) =>
          StrapiReturnDataUtils.parseObject(projectStrapi),
        ),
      );
  }

  getProjectForPlanAndBom(projectId: string): Observable<Project> {
    const queryParams: StrapiQueryParams = {
      populate: {
        planFile: true,
        batterySvg: true,
        terminalCover: {
          populate: {
            schemaFile: true,
          },
        },
        client: {
          populate: {
            logo: true,
          },
        },
        installer: true,
        schemaFile: true,
        cabinets: {
          populate: {
            schemaFile: true,
          },
        },
        rack: {
          populate: {
            schemaFile: true,
          },
        },
        bom: {
          populate: {
            schemaFile: true,
          },
        },
        battery: {
          populate: {
            batterySvg: true,
            schemaFile: true,
            connector: {
              populate: {
                schemaFile: true,
                terminalCover: {
                  populate: {
                    schemaFile: true,
                  },
                },
              },
            },
          },
        },
      },
    };
    const paramsSt = stringify(queryParams);
    return this.http.get(`${this.URL}/${projectId}?${paramsSt}`).pipe(
      map((projectStrapi) => {
        return StrapiReturnDataUtils.parseObject(projectStrapi) as Project;
      }),
    );
  }

  updateProject(projectToUpdate: Partial<ProjectCreate>): Observable<Project> {
    const paramsSt = stringify(this.queryParams);
    const url = `${this.URL}/${projectToUpdate.id}?${paramsSt}`;
    const body = {
      data: {
        ...projectToUpdate,
      },
    };
    return this.http.put(url, body).pipe(
      map((response) => {
        return StrapiReturnDataUtils.parseObject(response);
      }),
    );
  }

  createProject(projectToCreate: ProjectCreate): Observable<Project> {
    const projectStatus = ProjectStatus.ORDER_ENTRY;
    const body = {
      data: {
        ...projectToCreate,
        projectStatus,
      },
    };
    return this.http.post<Project>(this.URL, body).pipe(
      map((response) => {
        return StrapiReturnDataUtils.parseObject(response);
      }),
    );
  }

  deleteProject(projectId: string) {
    const url = `${this.URL}/${projectId}`;
    return this.http.delete(url);
  }

  purchaseOrderDoesNotExist(purchaseOrder: string, id: number) {
    if (!purchaseOrder) {
      return of(true);
    }

    const filters: Record<string, any> = {
      purchaseOrder: {
        $eq: purchaseOrder,
      },
    };
    if (id) {
      filters['id'] = {
        $ne: id,
      };
    }
    const query = stringify(
      {
        filters,
        pagination: {
          limit: 1,
        },
      },
      { encode: false },
    );
    return this.http
      .get<{ data: any[] }>(`${this.URL}?${query}`)
      .pipe(map(({ data }) => data.length === 0));
  }
}
