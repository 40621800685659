import { NzUploadFile } from 'ng-zorro-antd/upload';
import { StrapiFile } from '../models/strapi/strapi-file.model';

export class FileMapper {
  static strapiFilesToNzUploadFiles(strapiFiles: StrapiFile[]): NzUploadFile[] {
    return strapiFiles.map((strapiFile) => {
      return FileMapper.strapiFileToNzUploadFile(strapiFile);
    });
  }
  static strapiFileToNzUploadFile(strapiFile: StrapiFile): NzUploadFile {
    return {
      uid: strapiFile.id ? strapiFile.id.toString() : '',
      size: strapiFile.size,
      name: strapiFile.name,
      filename: strapiFile.name,
      url: strapiFile.url,
      status: 'done',
    } as NzUploadFile;
  }

  static strapiFileToIds(strapiFiles: StrapiFile[]): number[] {
    return strapiFiles.map((strapiFile) => {
      return strapiFile.id;
    });
  }

  static nzUploadFilesToIds(strapiFiles: NzUploadFile[]): number[] {
    return strapiFiles.map((strapiFile) => {
      return parseInt(strapiFile.uid);
    });
  }
}
