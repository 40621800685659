import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { stringify } from 'qs';
import { map, Observable } from 'rxjs';
import StrapiReturnDataUtils from '../../helpers/strapi-return-data.helper';
import { BatteryMapper } from '../../mappers';
import {
  BatteryLight,
  StrapiPageResult,
  StrapiQueryParams,
} from '../../models';

@Injectable({
  providedIn: 'root',
})
export class BatteryService {
  private readonly URL = 'api/batteries';

  private queryParamsLight: StrapiQueryParams = {
    fields: ['partNumber'],
  };

  constructor(private readonly http: HttpClient) {}

  getAllLight(): Observable<BatteryLight[]> {
    const paramsSt = stringify(this.queryParamsLight);

    return this.http
      .get<StrapiPageResult<BatteryLight>>(`${this.URL}?${paramsSt}`)
      .pipe(
        map((batteries) => {
          return StrapiReturnDataUtils.parseArray(batteries) as BatteryLight[];
        }),
      );
  }

  getOptions() {
    return this.getAllLight().pipe(
      map((batteries) =>
        BatteryMapper.batteriesLightDtoToSelectOptions(batteries),
      ),
    );
  }
}
