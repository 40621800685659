import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { stringify } from 'qs';
import { map, Observable } from 'rxjs';
import StrapiReturnDataUtils from '../../helpers/strapi-return-data.helper';
import { CabinetMapper } from '../../mappers';
import {
  Cabinet,
  CabinetLight,
  StrapiPageResult,
  StrapiQueryParams,
} from '../../models';

@Injectable({ providedIn: 'root' })
export class CabinetService {
  private readonly URL = 'api/cabinets';

  private queryParamsLight: StrapiQueryParams = {
    fields: ['reference'],
  };

  constructor(private readonly http: HttpClient) {}

  getAllCabinets() {
    return this.http.get<Cabinet[]>(this.URL);
  }

  getAllLight(): Observable<CabinetLight[]> {
    const paramsSt = stringify(this.queryParamsLight);

    return this.http
      .get<StrapiPageResult<CabinetLight>>(`${this.URL}?${paramsSt}`)
      .pipe(
        map((cabinets) => {
          return StrapiReturnDataUtils.parseArray(cabinets) as CabinetLight[];
        }),
      );
  }

  getCabinetsOptions() {
    return this.getAllLight().pipe(
      map((cabinets) => CabinetMapper.cabinetsLightToOptions(cabinets)),
    );
  }
}
