import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { stringify } from 'qs';
import { map, Observable } from 'rxjs';
import StrapiReturnDataUtils from '../../helpers/strapi-return-data.helper';
import { InstallerMapper } from '../../mappers';
import {
  Installer,
  InstallerLight,
  StrapiPageResult,
  StrapiQueryParams,
} from '../../models';

@Injectable({
  providedIn: 'root',
})
export class InstallerService {
  private readonly URL = 'api/installers';

  private queryParamsLight: StrapiQueryParams = {
    fields: ['society', 'firstName', 'lastName'],
  };

  constructor(private readonly http: HttpClient) {}

  getAllLight(): Observable<InstallerLight[]> {
    const paramsSt = stringify(this.queryParamsLight);

    return this.http
      .get<StrapiPageResult<Installer>>(`${this.URL}?${paramsSt}`)
      .pipe(
        map((installers) => {
          return StrapiReturnDataUtils.parseArray(
            installers,
          ) as InstallerLight[];
        }),
      );
  }

  getInstallersOptions() {
    return this.getAllLight().pipe(
      map((installers) => InstallerMapper.installersLightToOptions(installers)),
    );
  }
}
