import { Pipe, PipeTransform } from '@angular/core';
import { InstallerMapper } from '../mappers';
import { Installer, InstallerMinimal } from '../models';

@Pipe({
  name: 'formatInstallerLabel',
  standalone: true,
})
export class FormatInstallerLabelPipe implements PipeTransform {
  transform(installer: Installer | InstallerMinimal | undefined) {
    return installer ? InstallerMapper.formatInstallerLabel(installer) : '';
  }
}
