import { Client, ClientLight, ClientMinimal, SelectOption } from '../models';

export class ClientMapper {
  static clientDtoToClientMinimal(clientDto: Client): ClientMinimal {
    return {
      name: clientDto?.name,
      logo: clientDto?.logo?.formats?.xsmall?.url ?? clientDto.logo?.url,
    };
  }

  static clientsLightToOptions(clients: ClientLight[]): SelectOption[] {
    return clients.map((client) => this.clientLightToOption(client));
  }

  static clientLightToOption(client: ClientLight): SelectOption {
    return {
      value: client.id,
      label: client.name,
    };
  }
}
