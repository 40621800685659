
/**
 * Utility to convert enum to label
 */
export class ProjectSectionHelper {
  /**
   * Convert ProjectStatus enum to label translate key
   */
  static getOptions() {
    return [16, 35, 50, 70];
  }
}
