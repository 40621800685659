import { OrientationMapper } from '../mappers/orientation.mapper';

export const enum Orientation {
  'DEG0' = 'DEG0',
  'DEG90' = 'DEG90',
  'DEG180' = 'DEG180',
  'DEG270' = 'DEG270',
}

/**
 * Utilities of Orientation Enum
 */
export class OrientationHelper {
  static enumToLabelKey(status: Orientation) {
    switch (status) {
      case Orientation.DEG0:
        return '0';
      case Orientation.DEG90:
        return '90';
      case Orientation.DEG180:
        return '180';
      case Orientation.DEG270:
        return '270';
      default:
        return '';
    }
  }

  static enumToNumber(orientation: Orientation) {
    switch (orientation) {
      case Orientation.DEG0:
        return 0;
      case Orientation.DEG90:
        return 90;
      case Orientation.DEG180:
        return 180;
      case Orientation.DEG270:
        return 270;
    }
  }

  static getConstantList(): Orientation[] {
    return [Orientation.DEG0, Orientation.DEG90];
  }

  static getOptions() {
    return OrientationMapper.orientationsToOptions(this.getConstantList());
  }
}
