import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { stringify } from 'qs';
import { map } from 'rxjs';
import StrapiReturnDataUtils from '../../helpers/strapi-return-data.helper';
import { RackMapper } from '../../mappers';
import { Rack, StrapiPageResult, StrapiQueryParams } from '../../models';

@Injectable({ providedIn: 'root' })
export class RackService {
  private readonly URL = 'api/racks';

  private queryParams: StrapiQueryParams = {
    fields: ['reference'],
  };

  constructor(private readonly http: HttpClient) {}

  getAllRacksLight() {
    const paramsSt = stringify(this.queryParams);

    return this.http
      .get<StrapiPageResult<Rack>>(`${this.URL}?${paramsSt}`)
      .pipe(
        map((racks) => {
          return StrapiReturnDataUtils.parseArray(racks);
        }),
      );
  }

  getRacksOptions() {
    return this.getAllRacksLight().pipe(
      map((racks) => RackMapper.racksLightToOptions(racks)),
    );
  }
}
