import { BatteryMapper, CabinetMapper, ClientMapper, RackMapper } from '.';
import { Project, ProjectForList } from '../models';

export class ProjectMapper {
  static projectDtoToProjectForList(projectDto: Project): ProjectForList {
    return {
      ...projectDto,
      battery: projectDto.battery
        ? BatteryMapper.batteryDtoToBatteryMinimal(projectDto.battery)
        : undefined,
      client: projectDto.client
        ? ClientMapper.clientDtoToClientMinimal(projectDto.client)
        : undefined,
      cabinets: projectDto.cabinets
        ? CabinetMapper.cabinetsToCabinetsLight(projectDto.cabinets)
        : undefined,
      rack: projectDto.rack
        ? RackMapper.rackToRackLight(projectDto.rack)
        : undefined,
    };
  }
}
