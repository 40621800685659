export default class StrapiReturnDataUtils {
  // eslint-disable-next-line
  static parseObject(val: any) {
    if (val.data) {
      val = this.parseData(val);
    }
    if (val.data === null) {
      val = undefined;
    } else {
      val = this.parseAttributes(val);
    }
    return val;
  }

  static parseO<T>(val: T): T {
    return StrapiReturnDataUtils.parseObject(val);
  }

  // eslint-disable-next-line
  static parseArray(val: any) {
    if (val.data) {
      val = val.data;
    }
    // eslint-disable-next-line
    val = val.map((v: any) => this.parseObject(v));
    return val;
  }

  // eslint-disable-next-line
  static parseData(val: any) {
    return val.data;
  }

  // eslint-disable-next-line
  static parseAttributes(val: any) {
    let newVal = val;
    if (val.attributes) {
      newVal = val.attributes;
      newVal.id = val.id;
    }
    if (newVal && Array.isArray(newVal)) {
      newVal = newVal.map((v) => this.parseObject(v));
    } else {
      const listKey = Object.keys(newVal);
      listKey.forEach((key) => {
        if (newVal[key] && newVal[key].data !== undefined) {
          newVal[key] = this.parseObject(newVal[key]);
        } else {
          if (newVal[key] && Array.isArray(newVal[key])) {
            // eslint-disable-next-line
            newVal[key] = newVal[key].map((v: any) => this.parseObject(v));
          }
        }
      });
    }
    return newVal;
  }
}
