import { Rack, RackLight, SelectOption } from '../models';

export class RackMapper {
  static racksLightToOptions(racks: RackLight[]): SelectOption[] {
    return racks.map((rack) => {
      return this.rackLightToOption(rack);
    });
  }

  static rackLightToOption(rack: RackLight): SelectOption {
    return {
      value: rack.id,
      label: rack.reference,
    };
  }

  static racksToRacksLight(racks: Rack[]): RackLight[] {
    return racks.map((rack) => {
      return this.rackToRackLight(rack);
    });
  }

  static rackToRackLight(rack: Rack): RackLight {
    const { id, reference } = rack;
    return {
      id,
      reference,
    };
  }
}
