import {NzSegmentedOptions} from 'ng-zorro-antd/segmented';
import {NzSegmentedOption} from 'ng-zorro-antd/segmented/types';
import {ProjectStatusMapper} from '../mappers';

export enum ProjectStatus {
  ORDER_ENTRY = 'ORDER_ENTRY',
  PLAN_DONE = 'PLAN_DONE',
  PLAN_VALIDATED = 'PLAN_VALIDATED',
  ORDER_TO_BE_DELIVER = 'ORDER_TO_BE_DELIVER',
  TO_BE_INSTALLED = 'TO_BE_INSTALLED',
  TO_BE_INVOICED = 'TO_BE_INVOICED',
  INVOICED = 'INVOICED',
}

const enumToLabel = {
  ORDER_ENTRY: 'Order Entry',
  PLAN_DONE: 'Plan done',
  PLAN_VALIDATED: 'Plan validated',
  ORDER_TO_BE_DELIVER: 'Order to be delivered',
  TO_BE_INSTALLED: 'To be installed',
  TO_BE_INVOICED: 'To be invoiced',
  INVOICED: 'Invoiced',
}

/**
 * Utility to convert enum to label
 */
export class ProjectStatusHelper {
  /**
   * Convert ProjectStatus enum to label translate key
   * @param status
   */
  static enumToLabelKey(status: ProjectStatus) {
    return enumToLabel[status] ?? '';
  }

  static getConstantList(): ProjectStatus[] {
    return [
      ProjectStatus.ORDER_ENTRY,
      ProjectStatus.PLAN_DONE,
      ProjectStatus.PLAN_VALIDATED,
      ProjectStatus.ORDER_TO_BE_DELIVER,
      ProjectStatus.TO_BE_INSTALLED,
      ProjectStatus.TO_BE_INVOICED,
      ProjectStatus.INVOICED,
    ];
  }

  static getOptions() {
    return ProjectStatusMapper.projectStatusesToOptions(this.getConstantList());
  }

  static getNzSegmentedOptions(): NzSegmentedOptions {
    return ProjectStatusMapper.projectStatusToSegmentedOptions(
      this.getConstantList(),
    );
  }

  static updateDisabledNzSegmentedOptions(
    options: NzSegmentedOptions,
    currentStatus?: ProjectStatus,
  ): NzSegmentedOptions {
    return options.map((item) => {
      const option = item as NzSegmentedOption;

      if (
        currentStatus === ProjectStatus.ORDER_ENTRY &&
        option.value !== ProjectStatus.ORDER_ENTRY
      ) {
        option.disabled = true;
      }

      return option;
    });
  }
}
