export enum ProjectStatusFilter {
  ALL_PROJECTS = 'ALL_PROJECTS',
  TO_DRAW = 'TO_DRAW',
  URGENT_DELIVERIES = 'URGENT_DELIVERIES',
}

/**
 * Utility to convert enum to label
 */
export class ProjectStatusFilterHelper {
  /**
   * Convert ProjectStatusFilter enum to label translate key
   * @param status
   */
  static enumToLabelKey(status: ProjectStatusFilter) {
    switch (status) {
      case ProjectStatusFilter.ALL_PROJECTS:
        return 'All project';
      case ProjectStatusFilter.TO_DRAW:
        return 'To draw';
      case ProjectStatusFilter.URGENT_DELIVERIES:
        return 'Urgent deliveries';
    }
  }
}
