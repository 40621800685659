export enum TypeConnector {
  INTERBATTERY = 'INTERBATTERY',
  METAL_BAR = 'METAL_BAR',
  DISJONCTEUR_PLUS = 'DISJONCTEUR_PLUS',
  DISJONCTEUR_MOINS = 'DISJONCTEUR_MOINS',
  POINT_DE_MILIEU = 'POINT_DE_MILIEU',
  POINT_DE_MILIEU_SINGLE = 'POINT_DE_MILIEU_SINGLE',
  POINT_DE_MILIEU_INTER_ETAGE = 'POINT_DE_MILIEU_INTER_ETAGE',
  INTER_ETAGE = 'INTER_ETAGE',
  SNAIL = 'SNAIL',
}
